/* eslint-disable @typescript-eslint/no-floating-promises */
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {errorInCheckoutSfParams} from '@wix/bi-logger-ec-sf';
import {ICart} from '@wix/wixstores-graphql-schema/dist/es/src';
import {EMPTY_CART_ID, ORIGIN, PaymentMethodType} from '../../components/cart/constants';
import {CartType} from '@wix/wixstores-client-core/dist/es/src/types';
import {ShippingRuleStatus} from '@wix/wixstores-client-core/dist/src/types/shipping-rule-status';
import {IPaymentMethod} from '@wix/wixstores-graphql-schema';

type SetDestinationBIData = {
  cartId: string;
  cartType: string;
  shippingDestinationCountry?: string;
  shippingDestinationState?: string;
};

export class BIService {
  private readonly siteStore: SiteStore;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
  }

  private baseBiParams(cart: ICart) {
    return {origin: ORIGIN, cartId: cart?.cartId, storeId: cart?.storeId};
  }

  public clickOnShippingDestinationInCartPageSf(cart: ICart, cartType: string): void {
    this.siteStore.biLogger.clickOnShippingDestinationInCartPageSf({
      ...this.baseBiParams(cart),
      ...BIService.setDestinationBIData(cart, cartType),
    });
  }

  public clickOnCheckoutWithEWalletSf(cart: ICart, cartType: string, paymentMethods: IPaymentMethod[] = []): void {
    this.siteStore.biLogger.clickOnCheckoutWithEWalletSf({
      ...this.baseBiParams(cart),
      ...BIService.setDestinationBIData(cart, cartType),
      is_with_ewallet_payment: true,
      paymet_providers: this.stringifyPaymentMethods(paymentMethods),
    });
  }

  public clickContinueEWalletModalSf(cart: ICart, cartType: string, paymentMethods: IPaymentMethod[] = []): void {
    this.siteStore.biLogger.clickContinueEWalletModalSf({
      ...this.baseBiParams(cart),
      cartType,
      paymet_providers: this.stringifyPaymentMethods(paymentMethods),
    });
  }

  private stringifyPaymentMethods(paymentMethods: IPaymentMethod[]) {
    return paymentMethods.map((m) => m.name).join(',');
  }

  public updateClickedInSelectYourShippingDestinationPopupSf(
    cart: ICart,
    cartType: string,
    changedDestination: {country: string; subdivision: string; zipCode: string}
  ): void {
    this.siteStore.biLogger.updateClickedInSelectYourShippingDestinationPopupSf({
      ...this.baseBiParams(cart),
      shippingDestinationCountry: changedDestination.country,
      shippingDestinationState: changedDestination.subdivision,
      hasZipCode: !!changedDestination.zipCode,
      cartId: cart.cartId,
      cartType,
    });
  }

  public errorInCheckoutSf(cart: ICart): void {
    const {destination} = cart;
    const subdivision = destination.subdivision ? ` - ${destination.subdivision}` : /* istanbul ignore next */ '';
    const destinationStr = `${destination.country}${subdivision}`;
    const params: errorInCheckoutSfParams = {
      ...this.baseBiParams(cart),
      field: 'destination',
      message: `cannot ship to destination ${destinationStr}`,
      stage: 'cart',
    };
    this.siteStore.biLogger.errorInCheckoutSf(params);
  }

  public cancelClickedInSelectYourShippingDestinationPopupSf(cart: ICart, cartType: CartType): void {
    this.siteStore.biLogger.cancelClickedInSelectYourShippingDestinationPopupSf({
      ...this.baseBiParams(cart),
      cartType,
    });
  }

  public cartClickOnContinueShoppingSf(cart: ICart): void {
    this.siteStore.biLogger.cartClickOnContinueShoppingSf({
      ...this.baseBiParams(cart),
    });
  }

  public clickOnAddNoteToSellerSf(cart: ICart): void {
    this.siteStore.biLogger.clickOnAddNoteToSellerSf({
      ...this.baseBiParams(cart),
    });
  }

  public clickOnApplyPromotionalCodeSf(cart: ICart): void {
    this.siteStore.biLogger.clickOnApplyPromotionalCodeSf({
      ...this.baseBiParams(cart),
    });
  }

  public applyACouponSf(cart: ICart): void {
    this.siteStore.biLogger.applyACouponSf({
      ...this.baseBiParams(cart),
      couponId: cart.appliedCoupon.couponId,
      name: cart.appliedCoupon.name,
      couponCode: cart.appliedCoupon.code,
      type: cart.appliedCoupon.couponType,
      total_long: Math.round(cart.totals.discount * 1e5),
    });
  }

  public errorWhenApplyingACouponSf(cart: ICart, couponCode: string, errorCode: string): void {
    this.siteStore.biLogger.errorWhenApplyingACouponSf({
      ...this.baseBiParams(cart),
      couponCode,
      errorCode,
    });
  }

  public viewCartPageSf({
    cart,
    cartType,
    isEligibleForCheckoutInViewer,
    paymentMethods,
    numOfVisibleShippingOptions,
    shouldShowBuyerNote,
    shouldShowContinueShopping,
    shouldShowShipping,
    shouldShowTax,
    hasPickupOption,
  }: {
    cart: ICart;
    cartType: string;
    isEligibleForCheckoutInViewer: boolean;
    paymentMethods: IPaymentMethod[];
    numOfVisibleShippingOptions: number;
    shouldShowBuyerNote: boolean;
    shouldShowContinueShopping: boolean;
    shouldShowShipping: boolean;
    shouldShowTax: boolean;
    hasPickupOption: boolean;
  }): void {
    const isShippingCalculated = cart?.shippingRuleInfo?.status === ShippingRuleStatus.OK;

    const biData = {
      lineItemsCount: cart?.items.length || 0,
      checkoutType: isEligibleForCheckoutInViewer ? 'viewer' : 'not in viewer',
      viewMode: this.siteStore.viewMode,
      itemsCount: cart?.items.reduce((count, item) => count + item.quantity, 0) || 0,
      cartId: !cart || cart.cartId === EMPTY_CART_ID ? undefined : cart.cartId,
      cartType,
      num_of_paymet_providers: paymentMethods.length,
      is_with_ewallet_payment: paymentMethods.map((m) => m.type).includes(PaymentMethodType.eWallet),
      is_with_offline_payment: paymentMethods.map((m) => m.type).includes(PaymentMethodType.offline),
      paymet_providers: paymentMethods.map((m) => m.name).join(','),
      num_of_shipping: numOfVisibleShippingOptions,
      is_note: shouldShowBuyerNote,
      isContinueShopping: shouldShowContinueShopping,
      isDeliveryRates: shouldShowShipping,
      isTaxCosts: shouldShowTax,
      isPickupAvailable: hasPickupOption,
      shippingReason: isShippingCalculated ? '' : cart?.shippingRuleInfo?.status || 'unknown',
      isShippingCalculated,
    };

    this.siteStore.biLogger.viewCartPageSf({
      ...this.baseBiParams(cart),
      ...biData,
    });
  }

  private static setDestinationBIData(cart: ICart, cartType: string) {
    const biData: SetDestinationBIData = {
      cartId: cart.cartId,
      cartType,
    };
    if (cart.destination) {
      biData.shippingDestinationCountry = cart.destination.country;
      biData.shippingDestinationState = cart.destination.subdivision;
    }
    return biData;
  }
}
